.icon {
  font-size: 1.5rem;
  padding-right: 15px;
}

.title {
  font-size: 1.2rem;
  color: black;
  font-weight: bold;
}

.text {
  padding-left: 1.5rem;
  font-size: 1.2rem;
}

.header {
  display: flex;
  align-items: center;
}

.payment-text {
  display: flex;
  flex-direction: row;
  align-content: center;
  /* justify-content: space-between; */
  font-weight: bold;
  font-size: 1.6rem;
}

.payment {
  font-size: 1.4rem;
  width: 121px;
  height: 40px;
  color: white;
  border-radius: 15px;
  text-align: center;
  margin-left: 10px;
}

.payment span {
  font-size: 15px;
  letter-spacing: 1px;
}

.client-title {
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}

.client-text {
  font-weight: bold;
  font-size: 1.6rem;
  padding-right: 1.5rem;
}

.quittance-title {
  font-size: 1.2rem;
}

.quittance-text {
  font-weight: bold;
  font-size: 1.6rem;
  /* padding-right: 1rem; */
}

.close-icon {
  position: absolute;
  top: 0;
  right: 1%;
  font-size: 1.4rem;
  cursor: pointer;
  z-index: 10;
  color: black;
}

.close-icon:hover {
  color: red;
}