.page-container {
  display: flex;
  justify-content: space-between;
  // height: 100%;
  // width: 100%;
  // transform: rotate(90deg);

  & .mission-order,
  & .mission-order-admin {
    // width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 auto;

    & .page-header {
      //   width: 30%;
      display: flex;
      justify-content: space-between;

      & .header-logo {
        // width: 30%;
        display: flex;
        flex-direction: column;

        & .walid-voyage-logo {
          width: 150px;
        }

        & .logo-text {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }

      & .header-text {
        font-size: 1rem;
      }
    }

    & .page-main {
      display: flex;
      flex-direction: column;
      align-items: center;

      & .page-main-header {
        font-size: 1.4rem;
      }

      & .page-main-table {
        display: flex;
        flex-direction: column;
        border: 1px solid black;

        & .table-row {
          display: flex;
          align-items: center;
          height: 40px;

          &.header {
            background-color: black;
            color: white;
            font-size: 1.2rem;
            padding-left: 10px;
          }

          & .table-item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid black;
            font-size: 1rem;
            padding: 10px;

            &.title {
              font-weight: normal;
              width: 300px;
              height: 100%;
              border-right: 1px solid black;
            }

            &.value {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    & .page-footer {
      height: 50px;
      // padding: 2% 0 10% 0;
      font-size: 1.1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .mission-order {
    padding-right: 20px;
    border-right: 1px dashed black;
  }

  & .mission-order-admin {
    padding-left: 20px;
  }
}

.admin-table {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.958);
  color: white;
  font-size: 1.1rem;
  margin-top: 20px;
  border: 1px solid gray;
  width: 91%;

  & .admin-table-row {
    display: flex;
    align-items: center;
    height: 35px;

    & .admin-table-title {
      display: flex;
      align-items: center;
      width: 300px;
      height: 100%;
      padding-left: 10px;
      border-right: 1px solid gray;
      border-bottom: 1px solid gray;
    }

    & .admin-table-value {
      display: flex;
      align-items: center;
      padding-left: 10px;
      height: 100%;
      width: 100%;
      border-bottom: 1px solid gray;
    }
  }
}

.actions-buttons {
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 30px;

  & .action-button {
    font-size: 30px;
    margin-bottom: 4px;
    margin-left: 1px;

    &.print-button {
      color: #00b4d8;
    }
  }
}

@media print {
  #print {
    transform: rotate(90deg) scale(1.1);
    margin-top: 280px;
    margin-left: -30px;
    width: 29.7cm;
    height: 21cm;
  }
}
