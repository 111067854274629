.setting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  & .center {
    height: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
  & .form-input {
    width: auto;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
  & .form-input input {
    display: none;
  }
  & .form-input label {
    display: block;
    width: 100%;
    height: 45px;
    margin-left: 0;
    line-height: 50px;
    text-align: center;
    background: #1172c2;

    color: #fff;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    text-transform: Uppercase;
    font-weight: 400;
    border-radius: 5px;
    cursor: pointer;
  }

  & .preview {
    height: 25vh;
  }

  #file-ip-2-preview {
    width: 100%;
    height: 200px;
  }
}
