.summary-box {
  /* width: 16%; */
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5%;
  align-items: center;
  border-radius: 20px;
  color: white;
  font-size: 1.2rem;
}

.box-title {
  font-size: 1.3rem;
}

.box-number {
  font-size: 1.3rem;
  font-weight: bold;
}

.box-icon {
  font-size: 2.2rem;
}
