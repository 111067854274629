.send-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}

.send-button.list {
  padding-top: 0;
  padding-bottom: 30px;
}

.button-loading {
  display: none;
  /* padding: 180px; */
}

.send-button-icon {
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 2px;
}

.actions-container {
  width: 100%;
  font-size: 1.7rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
