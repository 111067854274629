.filter-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: -4px 5px 14px -3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;
}

.filter-button {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 10px;
}
