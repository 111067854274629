.select-container {
  position: relative;
  padding: 5px;
  input {
    height: 45px;
    padding: 0 39px;
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    background: #fff;
    color: #232323;
    width: 100%;
  }
  & > span {
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 25px;
    background-color: #fff;
  }
  .options-container {
    padding-left: 37px;
    position: absolute;
    overflow-y: auto;
    max-height: 300px;
    z-index: 2;
    width: calc(100% - 10px);
    border-bottom: 1px solid #eee;
    box-shadow: 0px 2px 22px -10px rgba(0, 0, 0, 0.75);
    background: #fff;
    .option {
      width: 100%;
      border-bottom: 1px solid #eee;

      padding-top: 7px;
    }
  }
}
.image-contain {
  object-fit: contain;
}

.MediaCenterchekbox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 25px;
  height: 25px;

  z-index: 1;
}
.MediaCentercontainer {
  overflow-y: scroll;
  max-height: 50vh;
  margin: 20px;
  background-color: white;
}
.MediaCenterimg {
  width: 190px;
  height: 190px;
}
.Listtags {
  display: flex;
  padding: 3px;
}
.MediaCentervid {
  width: 190px;
  height: 150px;
  position: relative;
  z-index: 0;
}
.imgcontainer {
  position: relative;
  float: left;
  margin-left: 10px;
  margin-bottom: 10px;
}
.tagscontainer {
  display: flex;
  border: 1px solid #848484;
  padding: 5px;
  border-radius: 14px;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 15px;
}

.tag {
  display: inline;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
  margin-left: 5px;
}
.tagul {
  list-style: none;
  background: #f3f1f1;
  display: grid;
  position: absolute;
  z-index: 9000;

  color: black;
  text-align: left;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0px 0px 0.25em 0.25em;
  background-color: white;
  margin-left: 9px;
  width: 220px;
  max-height: 400px;
  overflow-y: auto;
}
.tagli {
  border-bottom: 1px solid #ecebeb;
  padding: 6px 5px;
  cursor: pointer;
}
.tagli:hover {
  list-style: none;
  background: #dddddd;
  color: #5cb85c;
}
.tag input {
  border: none;
  background-color: red;
}
.taginput {
  border: none;

  display: inline;
  padding: 0.2em 0.6em 0.3em;
  line-height: 1;
  color: #5cb85c;
  text-align: left;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em 0.25em 0px 0px;
  background-color: #eceaea;
  margin-left: 5px;
}

.Mediaviewimg {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.Mediaview {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.titleinfo {
  widows: 50%;
}
.btnleft {
  position: absolute;
  left: 0;
  top: 50%;
}
.btnright {
  position: absolute;
  right: 0;
  top: 50%;
}

.iconpicker {
  width: 200px;
  height: 250px;
  overflow-y: scroll;
  position: absolute;
  z-index: 9999;
  background-color: white;
}
.icon {
  display: inline-table;
  padding: 9px;
}
.ProgressBorder {
  width: 100%;
  height: 23px;

  margin: 10px;
  background-color: #5c5c5c;
}
.loading {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #ffffffe0;
  z-index: 99999;
  top: 0;
  left: 0;
}
.loading > img {
  margin-left: auto;
  margin-right: auto;
  margin-top: 16%;
  display: block;
}
.outlet {
  position: relative;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  color: white;
  background-color: #007bff;
  display: inline-block;
  padding: 10px;
  border-radius: 6px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #38628e;
}
.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}
.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.tox-notifications-container {
  display: none !important;
}
.order label {
  font-weight: bold;
}
.ordertotal {
  width: 100%;
  text-align: end;
}
.orderimg {
  width: 100%;
}
.pending , .Pending {
  background-color: #258ee0;
  text-align: center;
  border-radius: 9px;
  padding: 0px 5px 0px 5px;
  color: white;
  font-size: 13px !important;
  height: 35px;
  line-height: 35px;
 
}
.approved , .Approved {
  background-color: yellowgreen;
  text-align: center;
  border-radius: 9px;
  padding: 0px 5px 0px 5px;
  color: white;
  font-size: 13px !important;
  height: 35px;
  line-height: 35px;
 
}
.revoked  ,.Revoked {
  background-color: rgb(238, 73, 73);
  text-align: center;
  border-radius: 9px;
  padding: 0px 5px 0px 5px;
  color: white;
  font-size: 13px !important;
  height: 35px;
  line-height: 35px;
 
}
.completed , .Completed {
  background-color: rgb(84, 175, 101);
  text-align: center;
  border-radius: 9px;
  padding: 0px 5px 0px 5px;
  color: white;
  font-size: 13px !important;
  height: 35px;
  line-height: 35px;
 
}
.canceled , .Canceled{
  background-color: rgb(238, 73, 73);
  text-align: center;
  border-radius: 9px;
  padding: 0px 5px 0px 5px;
  color: white;
  font-size: 13px !important;
  height: 35px;
  line-height: 35px;
 
}
