.center {
  height: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.form-input {
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: -3px -3px 7px rgba(94, 104, 121, 0.377),
    3px 3px 7px rgba(94, 104, 121, 0.377);
}
.form-input input {
  display: none;
}
.form-input label {
  display: block;
  width: 45%;
  height: 45px;
  margin-left: 25%;
  line-height: 50px;
  text-align: center;
  background: #1172c2;

  color: #fff;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  text-transform: Uppercase;
  font-weight: 400;
  border-radius: 5px;
  cursor: pointer;
}

.form-input img {
  width: 100%;
  /* display: none; */

  margin-bottom: 30px;
}
