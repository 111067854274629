.alert-content-container {
    display: flex;
    justify-content: space-between;
    font-size: 1.05rem;
    color: white;
    height: 40px;
    box-shadow: -3px 4px 9px 3px rgb(0 0 0 / 7%);
}

.alert-content {
    background-color: #fff;
    color: black;
    display: flex;
    flex-wrap: nowrap;
    gap: 7px;
    width: 100%;
    align-items: center;
    padding-left: 5px;
}

.alert-important {
    padding: 0 5px;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
}