// Your variable overrides
$sidebar-bg-color: #1f1e2e;
$sidebar-color: #959595 !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #999999 !default;
$submenu-bg-color: rgba(38, 36, 56, 0.9) !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: rgba(38, 36, 56, 0.9) !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
@import "~react-pro-sidebar/dist/scss/styles.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

$sidebar-bg-color: #1d1d1d;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    // .sidebar-btn {
    //   padding: 1px 15px;
    //   border-radius: 40px;
    //   background: rgba(255, 255, 255, 0.05);
    //   color: #adadad;
    //   text-decoration: none;
    //   margin: 0 auto;
    //   height: 35px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   span {
    //     margin-left: 5px;
    //     font-size: 13px;
    //   }
    //   &:hover {
    //     color: rgb(87,63,189);
    //   }
    // }
  }
  .logo-container {
    // background: #444;
    color: rgb(87, 63, 189);
    height: 60px;
    // line-height: 60px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      padding: 0 20px;
    }
    .btn-collapse {
      width: 80px;
      text-align: center;
      color: #999;
      &:hover {
        color: rgb(87, 63, 189);
      }
    }
  }
  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
    }
  }
  .sidebar-footer {
    padding: 10px;
    font-size: 12px;
    font-weight: 100;
    span {
      color: rgb(87, 63, 189);
    }
  }
  // -------------------------------------------------
  main {
    min-height: 100vh;
    background: #f5f6fa;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .outlet {
      padding: 20px 2%;
      .container {
        background: #fff;
        -webkit-box-shadow: -1px 0px 41px -29px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -1px 0px 41px -29px rgba(0, 0, 0, 0.75);
        box-shadow: -1px 0px 41px -29px rgba(0, 0, 0, 0.75);
      }
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }
  .btn-toggle {
    display: none;
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}
header {
  display: flex;
  padding: 0px 2%;
  height: 60px;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 10px 30px rgba(0, 0, 0, 0.08);
  &.rtl {
    direction: rtl;
    text-align: right;
  }
  .header-btns {
    position: relative;
    .header-btn {
      cursor: pointer;
      font-size: 25px;
      padding: 5px;
      display: inline-block;
      color: #2b2b2b;
      &:hover {
        animation: hovereffect 0.5s ease-in-out 0.5s;
        color: rgb(87, 63, 189);
      }
    }
  }
}
@keyframes hovereffect {
  0% {
    transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(0);
  }
  60% {
    transform: rotateZ(15deg);
  }
  100% {
    transform: rotateZ(0);
  }
}
.logomut {
  box-shadow: -1px -1px 21px -10px gray;
  border-radius: 80%;
  display: inline-block;
  vertical-align: middle;
  background: white;

  margin-left: 5px;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: white;
}
