.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.label {
  width: 100%;
  font-size: 1.2rem;
  font-weight: bold;
  color: black;
}
