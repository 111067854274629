.task-content-container {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    color: white;
    box-shadow: -3px 4px 9px 3px rgb(0 0 0 / 7%);
}


.task-content {
    background-color: #fff;
    color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 5px;
}

.task-payment {
    padding: 0 5px;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
}

.task-data {
    display: flex;
    align-items: center;
    height: 40px;
}

.task-icon {
    padding-right: 10px;
    display: flex;
    align-items: center;
}

.bus-icon {
    color: #0077b6;
    font-size: 37px;
}

.arrow-icon {
    color: #02c39a;
    font-size: 28px;
}

.rotate > svg {
    transform: rotateY(180deg)
}