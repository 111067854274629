.stats {
  text-align: center;
  padding: 0 0 5px 0;
  h4 {
    padding: 0;
    color: #333;
    text-transform: uppercase;
    font-weight: 200;
    text-align: left;
    font-size: 15px;
    height: 35px;
    margin: 0;
    border-bottom: 1px solid #eee;
    span {
      display: inline-block;
      vertical-align: middle;
      width: 35px;
      height: 35px;
      font-size: larger;
      color: #fff;
      text-align: center;
      line-height: 35px;
      margin-right: 7px;
      border: 1px solid #eee;
    }
  }
  div {
    padding: 10px;
  }
  span {
    color: #777;
    font-size: smaller;
    font-weight: 200;

    &.numb {
      font-weight: bold;
      font-size: x-large;
      margin: 5px;
    }
  }
}
.container {
  h4.f-200 {
    padding: 0 10px;
    color: #333;
    text-transform: uppercase;
    font-weight: 200;
    text-align: left;
    font-size: 15px;
    height: 35px;
    line-height: 35px;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #eee;
  }
}
.grid-product {
  line-height: 50px;
  img {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 4px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
  }
}
// .grid-product-price {
// }
