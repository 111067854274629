.maintenance-note {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.maintenance-note-p {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.maintenance-note-read-more-button {
  color: blue;
  cursor: pointer;
  margin-left: 5px;
}

.maintenance-note-read-more-button:hover {
  text-decoration: underline;
}
