.dashboard-container {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.dashboard-summary {
  height: 50%;
  display: flex;
  gap: 1%;
}

.dashboard-summary a {
  width: 16%;
}

.dashboard-summary a:hover {
  color: white;
  text-decoration: none;
}

.dashboard-rest {
  display: flex;
  justify-content: space-between;
}

.dashboard-alerts {
  width: 600px;
}

.dashboard-alerts-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
}

.tasks-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.alerts-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
