.quittance-container {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  font-size: 1.2rem;
}

.quittance-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid black;

  & .table-row {
    display: flex;
    height: 35px;

    &.header {
      background-color: gold;
      font-weight: 550;
    }

    & .table-item {
      width: 50%;
      height: 100%;
      border-bottom: 1px solid black;
      // padding-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.left {
  border-right: 1px solid black;
}
