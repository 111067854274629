.auth {
  background-size: 27%;

  // background-color: rgba(53, 88, 199, 0.05);
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom;

  & > div {
    min-height: 100vh;
    position: relative;
    // background: rgba(255, 255, 255, 0.9);
    .register {
      position: absolute;
      transform: translate(-50%, 0%);
      top: 20%;
      left: 50%;
      background: #fff;
      box-shadow: 0 0 10px gray;
    }
    // input {
    //   border-left: 4px solid rgb(53, 88, 199) !important;
    //   &.error {
    //     border-left: 4px solid rgb(201, 74, 36) !important;
    //   }
    // }
  }
}

.login-container {
  display: flex;
  align-items: center;
}

.login-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 40%;
  position: relative;
  & .login-text {
    padding-bottom: 20px;
  }

  & .walid-voyage-logo {
    width: 40%;
    height: auto;
  }
}

.login-input-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 10px;
}

.mask {
  background-color: blue;
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 0.1;
}

.login-background-image {
  width: 100%;
  height: 100vh;
}
